.Skills {
  margin: 0;
  margin-top: 32px;
  padding-bottom: 32px;
  width: 100%;
  position: relative;
  background-color: #0e2a47;
  min-height: 700px;
}

.Skills .Content {
  margin: 0 auto;
  width: 80%;
  padding: 0;
  min-height: 700px;
  background-color: #0e2a47;
  z-index: 100;
}

.Skills .Content h2 {
  margin: 0;
  font-size: 48px;
}

.Skills .SkillsCol {
  margin-top: 32px;
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;
}

.Skills .SkillsTitle {
  width: 100%;
  display: grid;
  grid-template-rows: 1fr;
  grid-template-columns: 1fr 1fr;
  font-size: 28px;
  font-weight: 600;
  margin: 16px;
}

.Skills .Title {
}

.Skills .Value {
}

@media (max-width: 900px) {
  .Skills .Value {
    text-align: right;
  }
}

@media (max-width: 600px) {
  .Skills {
    font-size: 24px;
  }
}
