.Skill {
  width: 100%;
  display: grid;
  grid-template-rows: 1fr;
  grid-template-columns: 1fr 1fr;
  font-size: 28px;
  margin: 16px;
}

.Skill .Title {
}

.Skill .Value {
}

@media (max-width: 900px) {
  .Skill .Value {
    text-align: right;
  }
}

@media (max-width: 600px) {
  .Skill {
    font-size: 24px;
  }
}
