.Contact {
  margin: 0;
  margin-top: 42px;
  padding-bottom: 32px;
  width: 100%;
  position: relative;
  background-color: #0e2a47;
  min-height: 700px;
}

.Contact .Content {
  margin: 0 auto;
  width: 80%;
  padding: 0;
  min-height: 700px;
  background-color: #0e2a47;
  z-index: 100;
}

.Contact .Content h2 {
  margin: 0;
  font-size: 48px;
}

.Contact .ContactForm {
  margin: 0px auto 32px auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.Contact .ContactRow {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.Contact .ContactGroup {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  margin: 8px 0;
}

.ContactGroup label {
  margin-left: 12px;
  font-size: 18px;
}

.Contact .TextInputWrapperHalf {
  width: 50%;
  padding: 8px;
}

.Contact .TextInputWrapper {
  width: 100%;
  padding: 8px;
}

.Contact .TextInput {
  width: 100%;
  height: 43px;
  font-size: 18px;
  color: white;
  background-color: rgba(0, 0, 0, 0.8);
  outline: none;
  border-radius: 16px;
  border: 1px solid white;
  padding: 4px 8px;
  transition: all 200ms ease;
}

.Contact .TextInput:focus {
  border: 2px solid white;
}

.Contact .TextInput::placeholder {
  color: rgba(255, 255, 255, 0.9);
}

.Contact .Submit {
  -webkit-appearance: none;
  appearance: none;
  width: calc(100% - 16px);
  height: 43px;
  margin: 32px 0;
  font-size: 18px;
  color: white;
  background-color: #2264ab;
  outline: none;
  border: none;
  border-radius: 16px;
  transition: all 200ms ease;
  cursor: pointer;
}

.Contact .Submit:hover {
}

.FormStatus {
  padding: 0 8px;
  width: calc(100% - 32px);
  height: 50px;
  margin: 16px auto;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.FormErr {
  width: 100%;
  height: 50px;
  background-color: #c2211e;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 16px;
  font-size: 20px;
}

.FormSuccess {
  height: 50px;
  width: 100%;
  background-color: #007a00;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 16px;
  font-size: 20px;
}

.LoaderContainer {
  margin-top: 32px;
  width: 100%;
  height: 43px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Captcha {
  margin: 16px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (max-width: 900px) {
  .ContactRow {
    flex-direction: column;
  }
}
