.Experience {
  width: 60%;
  min-height: 100vh;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding-bottom: 64px;
}

.Experience .Back {
  position: absolute;
  top: 32px;
  left: 32px;
  width: 36px;
  height: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.Experience .Back img {
  width: 100%;
}

.Experience .Header {
  padding-top: 32px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.Experience .Header img {
  width: 300px;
  margin-bottom: 32px;
}

.Experience .Header h1 {
  width: 100%;
  text-align: left;
}

.Experience .Header h2 {
  width: 100%;
  text-align: left;
}

.Experience .Header a {
  width: 100%;
  text-align: left;
  margin-top: 16px;
  font-size: 24px;
  font-weight: 600;
}

.Experience .Header .Tech {
  width: 100%;
  text-align: left;
  margin-top: 24px;
  font-size: 20px;
  font-weight: 500;
}

.Company {
  width: 100%;
  margin: 32px 0;
}

.Company h3 {
  margin: 8px 0;
}

.Company p {
  font-size: 18px;
}

.Role {
  width: 100%;
  margin: 32px 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.Role h3 {
  width: 100%;
  text-align: left;
  margin: 8px 0;
}

.Role ul {
  width: 100%;
  list-style-position: inside;
  margin: 0;
  padding: 0;
  font-size: 18px;
}

.Role ul li {
  margin: 4px 0;
  text-indent: -26px;
}

.UI {
  margin: 16px 0 32px 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.Screenshot {
  margin: 16px 0;
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.Screenshot .Desktop {
  margin: 0 auto;
  width: calc(100% - 16px);
  border-radius: 8px;
}

.Screenshot .Mobile {
  position: absolute;
  width: 20%;
  bottom: 0px;
  right: 8px;
}

.Grid {
  margin: 16px 0;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr;
  row-gap: 16px;
  column-gap: 16px;
  width: 100%;
}

.Grid .Item {
  width: 70%;
  margin: 0 auto;
}

@media (max-width: 700px) {
  .Experience {
    width: 100%;
    padding: 0 16px;
  }

  .Experience .Back {
    left: 16px;
  }

  .Experience .Header img {
    width: 200px;
  }

  .Role ul {
    width: 100%;
    list-style-position: unset;
    margin: 0 auto;
    padding: 0 16px;
    font-size: 18px;
  }

  .Role ul li {
    margin: 4px 0;
    text-indent: 0;
  }

  .Experience .Desktop {
    border-radius: 4px;
  }

  .Grid .Item {
    width: 100%;
    margin: 0 auto;
  }
}
