.TextAreaGroup {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 8px;
  width: 100%;
}

.TextAreaGroup textarea {
  resize: vertical;
  margin-top: 8px;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  border-radius: 16px;
  border: 1px solid white;
  padding: 8px;
  font-size: 18px;
  outline: none;
  color: var(--text);
  transition: all 200ms ease;
}

.TextAreaGroup textarea:focus {
  border: 2px solid white;
}

.TextAreaGroup textarea::placeholder {
  color: rgba(255, 255, 255, 0.9);
}
