.Landing {
  width: 100%;
  min-height: 100vh;
  margin: 0;
  padding: 0;
}

.LandingSection {
  background-color: #0e2a47;
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.Content {
  width: 80%;
  height: 400px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.Content h1 {
  font-size: 64px;
  margin-bottom: 16px;
  font-weight: 800;
}

.Content p {
  font-size: 28px;
}

.Wave {
  width: 100%;
  height: 400px;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%) translateY(0%);
}

@media (max-width: 600px) {
  .Wave {
    object-fit: cover;
  }

  .Content h1 {
    font-size: 48px;
  }

  .Content p {
    font-size: 16px;
  }
}
