* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

:root {
  --background: #0e2a47;
  --text: #ffffff;
  --color1: #116d97;
  --color2: #00a9a5;
  --color3: #004669;
}

#root {
  background-color: var(--background);
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
label,
button,
ul,
a,
div {
  color: var(--text);
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Raleway', sans-serif;
}

p,
label,
input,
button,
ul,
a,
div,
textarea {
  font-family: 'Montserrat', sans-serif;
}

.error {
  color: red;
}

.invalid-feedback {
  width: 100%;
  margin: 8px 8px 0 8px;
  text-align: left;
}
