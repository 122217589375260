.About {
  margin: 0;
  padding-bottom: 32px;
  width: 100%;
  position: relative;
  padding-top: 64px;
  background-color: #0e2a47;
  min-height: 700px;
}

.Wave {
  z-index: 2;
  width: 100%;
  height: 400px;
  margin: 0;
  padding: 0;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%) translateY(0%) rotate(180deg);
}

.Content {
  margin: 0 auto;
  width: 80%;
  margin-top: 234px;
  padding: 0;
  min-height: 700px;
  background-color: #0e2a47;
  z-index: 100;
}

.Content h2 {
  margin: 0;
  font-size: 48px;
}

.AboutRow {
  min-height: 80vh;
  margin: 32px 0;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.AboutRow .Info {
  height: 100%;
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.Info p {
  font-size: 20px;
  margin: 8px 0;
}

.AboutRow .Image {
  height: 100%;
  width: 50%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.AboutRow .Image img {
  max-width: 80%;
  max-height: 60vh;
  box-shadow: 0 0 25px 3px rgba(0, 0, 0, 0.4);
}

@media (max-width: 1100px) {
  .AboutRow {
    flex-direction: column-reverse;
  }

  .AboutRow .Image {
    width: 100%;
    justify-content: center;
  }

  .AboutRow .Info {
    margin-top: 32px;
    width: 100%;
  }
}

@media (max-width: 700px) {
  .AboutRow .Image img {
    max-width: 100%;
  }

  .Info p {
    font-size: 16px;
  }
}

@media (max-width: 600px) {
  .Wave {
    object-fit: cover;
  }
}
