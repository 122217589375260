.Work {
  margin: 0;
  margin-top: 42px;
  padding-bottom: 64px;
  width: 100%;
  position: relative;
  background-color: #0e2a47;
}

.Work .Content {
  margin: 0 auto;
  width: 80%;
  padding: 0;
  background-color: #0e2a47;
  z-index: 100;
}

.Work .Content h2 {
  margin: 0;
  font-size: 48px;
}

.WorkGrid {
  width: 100%;
  margin: 48px auto;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-auto-rows: 1fr;
  column-gap: 24px;
}

@media (max-width: 850px) {
  .WorkGrid {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    row-gap: 16px;
    column-gap: 16px;
  }
}
