.Card {
  overflow: hidden;
  width: 100%;
  height: calc((80vw / 3) - 12px);
  margin: 0 auto;
  text-decoration: none;
  color: white;
  cursor: pointer;
  background-color: white;
  border-radius: 16px;
  overflow: hidden;
  position: relative;
  box-shadow: 0 0 25px 3px rgba(0, 0, 0, 0.4);
}

.Card img {
  width: 80%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
}

.Card .Overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.9);
  transition: opacity 200ms ease;
}

.Card .Overlay .Position {
  font-size: 24px;
  font-weight: 600;
}

.Card .Overlay .ViewMore {
  font-size: 24px;
  font-weight: 600;
  margin-top: 32px;
  text-decoration: underline;
}

@media (max-width: 1100px) {
  .Card .Overlay .Position {
    font-size: 18px;
  }

  .Card .Overlay .ViewMore {
    font-size: 18px;
  }
}

@media (max-width: 850px) {
  .Card {
    width: 100%;
    height: calc(0.4 * 100vw - 16px);
  }
}
